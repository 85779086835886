body
{
  overflow-x: hidden;
}

:root {
  --animate-delay: 0.8s;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;700&display=swap');


.m-t-1 {margin-top: 10px;}
.m-t-2 {margin-top: 20px;}
.m-t-3 {margin-top: 30px;}
.m-t-4 {margin-top: 40px;}
.m-t-5 {margin-top: 50px;}
.m-t-6 {margin-top: 60px;}

.m-b-1 {margin-bottom: 10px;}
.m-b-2 {margin-bottom: 20px;}
.m-b-3 {margin-bottom: 30px;}
.m-b-4 {margin-bottom: 40px;}
.m-b-5 {margin-bottom: 50px;}
.m-b-6 {margin-bottom: 60px;}

.p-t-1 {padding-top: 10px;}
.p-t-2 {padding-top: 20px;}
.p-t-3 {padding-top: 30px;}
.p-t-4 {padding-top: 40px;}
.p-t-5 {padding-top: 50px;}
.p-t-6 {padding-top: 60px;}

.logo
{
  text-align: center;
  margin: 10px auto;
}

.logo img
{

}

.rounded
{
  border-radius: 50%;
}

.regular
{
  font-weight: 300;
}

.bold
{
  font-weight: 500;
}

.thin
{
  font-weight: 100;
}

.special-text
{
  background:  #ff768d;
  color: #fff;
  padding: 3px;
  margin: 3px 0;
  display: inline-block;
}

.hover-effect
{

}

.hover-effect:hover
{

}

*
{
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  color: #333;
  font-weight: 300;
}

html, body, #root {
  height: 100%;
  position: relative;
}

a {
  text-decoration: none;
  color: #000;
  -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

ul {
  list-style: none;
}

body .Toastify .Toastify__toast-container .Toastify__toast--success{
  background-color: red;
}
body .Toastify .Toastify__toast-container .Toastify__toast--error{
  background-color: green;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.relative
{
  position: relative;
}

.height-full
{
  height: 100%;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

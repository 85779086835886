.hello-div-up
{
  background: #fffcf8;
  margin: 0!important;
}

.hello-div-down
{
  background: #ff768d;
  margin: 0!important;
}
